import React, { FC, ReactElement } from 'react';

import { graphql } from 'gatsby';

import { ConnectedPage } from '../connectors';
import { SliceRenderer } from '../containers';
import { MetaDataResource, transformToMetaData } from '../models/Meta';
import { PrismicSlice } from '../models/Prismic';

interface SingleSectorPageProps {
    data: {
        prismicSector: {
            dataRaw: {
                body: PrismicSlice[];
                meta: MetaDataResource[];
            };
        };
    };
}

const SingleSectorPage: FC<SingleSectorPageProps> = ({
    data,
}): ReactElement => {
    const apiData = data.prismicSector.dataRaw;

    const slices = apiData.body;
    const metaData = apiData.meta.length
        ? transformToMetaData(apiData.meta[0])
        : undefined;

    const backButtonData = {
        url: '/',
        label: 'Home',
    };

    return (
        <ConnectedPage metaData={metaData} className="single-sector-page">
            <SliceRenderer
                slices={slices}
                backButtonData={backButtonData}
                className="single-sector-page__slice-renderer"
            />
        </ConnectedPage>
    );
};

export default SingleSectorPage;

export const query = graphql`
    query SectorsPageComponents($id: String) {
        prismicSector(id: {eq: $id}) {
            dataRaw
        }
    }
`;
